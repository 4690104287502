<template>
	<div class="files" @drop.prevent="addFile" @dragover.prevent>
		<div class="head">
			<p>
				Legen Sie Dateien hier per Drag & Drop ab, oder wählen Sie eine Datei
				aus.
			</p>
			<label for="file-input">
				<p class="button">Datei auswählen</p>
			</label>
			<input
				type="file"
				ref="file"
				id="file-input"
				@change="addSingleFile"
				multiple
			/>
		</div>
		<div class="drag-area">
			<table>
				<thead>
					<th>Datei</th>
					<th>Größe</th>
					<th>Optionen</th>
				</thead>
				<tbody>
					<tr v-for="file in files" :key="file.name">
						<td>{{ file.name | truncate }}</td>
						<td>{{ (file.size / 1000000).toFixed(2) }} MB</td>
						<td>
							<unicon
								name="trash-alt"
								icon-style="line"
								width="21"
								height="21"
								@click="removeFile(file.name)"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { required, minLength, maxValue } from 'vuelidate/lib/validators';
export default {
	data() {
		return {
			files: [],
			filesMissing: false
		};
	},
	validations: {
		files: {
			$each: {
				size: {
					maxValue: maxValue(1000000000000000)
				}
			}
		}
	},
	filters: {
		truncate: function (str) {
			const n = 35;
			return str.length > n ? str.substr(0, n - 1) + '...' : str;
		}
	},
	methods: {
		async addFile(e) {
			let droppedFiles = e.dataTransfer.files;
			if (!droppedFiles) return;
			// this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
			[...droppedFiles].forEach(async (f) => {
				if (!this.files.some((file) => file.name == f.name)) {
					this.files.push(f);
					this.$v.files.$touch();
				}
			});
			this.filesMissing = false;
		},
		addSingleFile() {
			const fileList = this.$refs.file.files;
			[...fileList].forEach((f) => {
				if (!this.files.some((file) => file.name === f.name)) {
					this.files.push(f);
					this.$v.files.$touch();
				}
			});
			this.filesMissing = false;
		},
		removeFile(name) {
			this.files = this.files.filter((f) => f.name !== name);
			this.checkForErrors();
		},
		checkForErrors() {
			this.$v.files.$touch();
			if (!this.$v.$anyError) {
				this.filesMissing = false;
				return this.files;
			} else {
				this.filesMissing = true;
				return false;
			}
		},
		reset() {
			this.files = [];
			this.filesMissing = false;
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
.files {
	height: 100%;
	background-color: $background-color;
	border-radius: 10px;
	.head {
		display: grid;
		grid-template-columns: 3fr 1fr;
		font-size: 0.85rem;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 1rem;
		gap: 0.75rem;
		#file-input {
			display: none;
		}
		label {
			margin: 0;
			p {
				padding: 0.5rem 0.75rem;
			}
		}
	}
	.drag-area {
		padding: 0.75rem 1rem 0 1rem;
		.has-error {
			padding: 0 0 1rem 0;
			strong {
				color: $background-nok;
			}
		}
		table {
			border-spacing: 0;
			border-bottom: 1px solid $text-color;
			max-width: 100%;
			width: 100%;
			table-layout: fixed;
			thead {
				th {
					text-align: center;
					border-color: transparentize($color: #000, $amount: 0.75);
					&:last-of-type {
						width: 25%;
					}
					&:not(:last-of-type):not(:first-of-type) {
						width: 25%;
					}
					&:first-child {
						width: 50%;
					}
				}
			}
			tbody {
				tr {
					margin: 0.5rem 0;
					td {
						word-break: break-word; // to make content justify
						height: 100%;
						&:last-of-type {
							display: flex;
							align-items: center;
							align-content: center;
							justify-content: center;
							text-align: center;
							min-height: 100%;
						}
						&:not(:last-of-type):not(:first-of-type) {
							text-align: center;
						}
						.unicon {
							display: inline-flex;
							background-color: $grey;
							border-radius: 50%;
							padding: 6px;
							filter: opacity(0.5);
							&:hover,
							&:focus {
								filter: opacity(1);
							}
						}
					}
				}
			}
		}
	}
}
</style>
